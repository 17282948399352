.background {
    background: url(../IMG/background-2.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 5rem 0;
}

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 16px;
    padding: 20px;
    border-left: 3px solid #fe7153;
    border-right: 3px solid #fe7153;
    
    background-color: rgba(30, 30, 30, 0.48);

    color: #fff;
}

.card h2,
.card b {
    font-family: "Khand", sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
    color: white;
    margin-bottom: 0.5rem;
}

.card b {
    font-weight: 700;
}

.card:nth-child(1),
.card:nth-child(2) {
    margin-bottom: 2rem;
}

.card:nth-of-type(odd) h2,
.card:nth-of-type(odd) p {
    text-align: right;
}

.card:nth-child(2) {
    flex-direction: row-reverse;
}

.card:nth-child(2) h2,
.card:nth-child(2) p {
    text-align: left;
}

.card p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    padding-bottom: 1rem;
}

.box-text {
    width: 50%;
    padding: 10px;
}

.card:nth-child(1) .box-text,
.card:nth-child(3) .box-text {
    margin-left: 15px;
}

.card:nth-child(2) .box-text {
    margin-right: 15px;
}

.box-image {
    width: 50%;
}

.box-image img {
    width: 100%;
    border-radius: 16px;
    transition: 0.3s;
    cursor: pointer;
}

.box-image img:hover {
    transform: scale(1.05);
}

@media only screen and (max-width: 999px) {
    .card {
        flex-direction: column !important;
    }

    .card h2,
    .card p {
        text-align: center !important;
    }

    .card h2 {
        margin-top: 15px;
    }

    .card .box-text {
        margin: 0 !important;
        width: 100%;
    }

    .card .box-image {
        width: 100%;
        max-width: 100%;
        text-align: center;
    }

    .card .box-image img {
        width: 100%;
        max-width: 100%;
    }
}
