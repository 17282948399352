/* FAQ.css */
.faq-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.faq-container h2 {
    text-align: center;
    font-family: "Khand", sans-serif;
    font-weight: 600;
    font-size: 2.5rem;

    margin-top: 1rem;
}

.faq-list {
    margin-top: 20px;
}

.faq-item {
    border: 1px solid #000000;
    border-radius: 50px;
    padding: 15px 30px;
    margin-bottom: 1rem;
}

.faq-question {
    font-weight: bold;
    cursor: pointer;
    color: #333;
}

.faq-answer {
    margin-top: 10px;
    color: #666;
    font-size: 0.9em;
}
