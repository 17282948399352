a {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.box-form {
    padding: 20px;
    background-color: #fff;
    border-radius: 16px;
    border-left: 3px solid #222;
    border-right: 3px solid #222;
    margin: 2rem auto;
}

.event-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    transition: all 0.3s;
}

.event-infos p {
    font-family: 'Khand', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
}

.icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    font-size: 12px;
    text-align: center;
}

.icon-div:nth-child(1),
.icon-div:nth-child(2),
.icon-div:nth-child(3) {
    border-right: 1px solid #e2e2e2;
}

.icon-div p {
    max-width: 90%;
}

.sign-up-btn {
    font-family: 'Khand', sans-serif;
    font-size: 18px;
    font-weight: 600;
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #fe7153;
    color: #fff;
    border-radius: 50px;
    border: none;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.sign-up-btn:hover {
    transform: scale(1.005);
}

.icon-form {
    /* margin-right: 1rem; */
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
    .event-infos {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 0;
        row-gap: 2rem;
        margin-bottom: 1.5rem;
    }

    .event-infos .icon-div:nth-child(2) {
        border: none;
    }
}