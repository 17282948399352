* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

body {
  /* background-color: #F9F9F9; */
  background-image: url("./IMG/bg-detalhes.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

h1 {
  font-family: "Khand", sans-serif;
}

p {
  font-family: "Roboto", sans-serif;
}

.container {
  width: min(90%, 1000px);
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  body {
    background-position: left;
  }
}

@media only screen and (max-width: 425px) {
  body {
    background-image: none !important;
  }

  .songs-wrapper>div {
    padding-bottom: 3rem !important;
  }
}