.popup.active {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0.5s;
    z-index: 9999999;
}

.popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    width: min(90%, 600px);
    height: fit-content;
    max-height: 90vh;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: scroll;
}

.popup-content iframe {
    width: 100%;
    height: 700px;
}

.close-btn {
    float: right;
    border: none;
    cursor: pointer;
    background: none;
    margin-bottom: 1.5rem;
}