.container-geral {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box-carrossel {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 100%;
    height: 30rem;
}

.container-geral h2 {
    font-family: "Khand", sans-serif;
    font-weight: 600;
    font-size: 2.5rem;

    margin-top: 1rem;
}

.container-geral p {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.box-decoration {
    margin-top: 1.5rem;
    width: 80%;
    height: 2rem;

    display: flex;
    justify-content: end;
    align-items: center;
}

.swiper {
    width: 100%;
    height: 100%;

    /* display: flex !important;
    justify-content: center !important;
    align-items: center !important; */

    /* border: blue 1px solid; */

    /* padding: rem !important; */

    overflow: hidden !important;
}

.slide {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.slide-item {
    width: 19rem;
    height: 24rem;
    object-fit: cover;

    border-radius: 15px !important;

    /* border: yellow 1px solid; */
}

.swiper-button-next,
.swiper-button-prev {
    color: black !important;
    background-color: rgba(0, 0, 0, 0.305);
    width: 1rem !important;
    height: 1rem !important;
    border-radius: 8px;
    padding: 20px;
    font-size: 10px !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 1rem !important;
}

.guests-carousel {
    margin-bottom: 5rem;
}